@import '/src/mixins';

.portItem {
  display: flex;
  width: 96%;
  align-items: center;
  margin: 6px;
  background-color: 'primary.main';
  border-radius: 3px;
  padding: 4px;
  border-style: none;
  transition: background-color ease-in-out 0.1s;

  .portCountry {
    margin: 0;
    padding-right: 1em;
    font-size: 14px;
    text-align: left;
    color: 'text.secondary';
    font-weight: 800;
  }

  .portName {
    color: 'text.primary';
    font-weight: bold;
    text-align: left;
    margin: 0.5em;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: 'primary.light';
    cursor: pointer;
  }
}
