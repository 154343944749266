@import '/src/mixins';

.item-toggle-icon {
  cursor: pointer;
  &:hover {
    color: 'primary.dark';
  }

  &.disabled {
    color: 'action.disabled';
    cursor: not-allowed;
  }
  &.inverted {
    color: 'text.primary';
  }
}
