.polygon-points {
  overflow-y: auto;
  height: 10rem;
  scrollbar-width: thin;
}

.remove-polygon-position {
  background: transparent;
  color: grey;
  border-color: transparent;

  .close-panel-icon {
    font-size: 1.5rem;
  }

  &:hover {
    color: lightgray;
  }
}

.lat-lng-heading {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  gap: 20px;
}

.adjust-polynomial-points-button {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  button {
    background-color: grey;
    color: white;
    border-color: transparent;
    border-radius: 10px;
  }
  button:hover {
    background-color: lightgray;
  }
}
