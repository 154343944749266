.search-bar-container {
  position: fixed;
  top: 1rem;
  left: 6rem;
  width: 20.5rem;

  &.open {
    width: 26rem;
  }

  background: none;
  background-image: none !important;
  background-color: 'primary.main' !important;
  // transition: height 0.2s ease-in-out;

  .filterList {
    & > * {
      padding: 1rem 1.5rem 1rem 1rem;
    }
  }

  &.inline {
    position: unset;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
