@import '/src/mixins';

.specificRoutePanel {
  color: 'text.primary';
  text-align: left;
  height: 100%;
  overflow: hidden;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
}

.specificRouteTitleAndBackButtonContainer {
  text-align: left;
  overflow-y: hidden;
  padding: 5px;

  .specificRouteBackButton {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: 'text.primary';
    filter: brightness(1.4);
    margin-right: auto;
    padding: 3px;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: 'text.secondary';
      filter: brightness(1.6);
    }
  }
}

.specificRouteDescription {
  text-align: left;
  overflow-y: hidden;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 15px;
  align-content: start;
  padding-left: 15px;

  .specificRouteDescriptionTextArea {
    padding: 1px;
  }
}

.selectedRouteIncidentsContainer {
  text-align: left;
  overflow-y: hidden;

  .routeIncidentList {
    list-style: none;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 55px);
    margin-top: 0.5em;
    transition: height 0.5s ease-out;
    transition-delay: 0.3s;
    scroll-behavior: smooth;
    scrollbar-width: thin;
  }
}

.route-details-info-table {
  border: none;
  border-spacing: 0;
  td {
    padding: 0.2rem 0;
    border: none;
    text-align: left;
    color: 'text.primary';
  }
}
