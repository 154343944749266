@import '/src/mixins';

.incidentList {
  list-style: none;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 0.5em;
  margin-bottom: 0;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

.incidentList.filtersOpen {
  height: calc(100vh - 490px);
}

.incidentListTitle {
  margin: 0;
  margin-left: 0.3em;
  text-align: left;
  font-size: 1.17em;
  color: 'text.primary';
  flex: 8;
}

.download-incidents-button {
  border: none;
  background-color: 'primary.main';
  &:hover {
    background-color: 'secondary.dark';
    a {
      width: 100%;
      text-decoration: none;
      color: white;
    }
  }
  border-radius: 20px;
  border: 1px solid 'primary.main';

  a {
    width: 100%;
    text-decoration: none;
    color: black;
  }
}

.download-incidents-button-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin: 0.5em;
  margin-bottom: 1rem;

  .download-view-on-buttons {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 0.5rem;
    border: none;

    .download-view-on-buttons-cancel {
      background-color: red;
      border: none;
      border-radius: 20px;

      color: white;
      &:hover {
        background-color: rgba(255, 0, 0, 0.5);
      }
    }
    .download-view-on-buttons-download {
      border: none;
      background-color: green;
      border-radius: 20px;
      color: white;

      &:hover {
        background-color: rgba(1, 180, 1, 0.774);
      }
    }
    .download-view-on-buttons-select-all {
      border: none;
      background-color: 'primary.main';
      border-radius: 20px;
      color: 'text.primary';

      &:hover {
        background-color: rgba(128, 128, 128, 0.5);
      }
    }
  }
}
