@import '/src/mixins';

.filterItem {
  background-color: 'primary.main';
  margin: 2px 3px 0 3px;
  padding: 0;
  border-radius: 3px;
  color: 'text.primary';
  border: none;
  cursor: pointer;

  &:hover {
    background-color: 'secondary.dark';
  }
}

button.filterItem {
  inline-size: stretch;
}

.filtersContent {
  max-height: fit-content;
}

.filtersContent[aria-expanded='false'] {
  max-height: 0px;
}

.filtersHeader {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
