@import '/src/mixins';

#incidents-date-range-indicator {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 50px;
  left: 50%;

  margin-top: 2rem;

  transform: translate(-50%, 0);
  text-wrap: nowrap;

  .filterItem {
    background-color: #3131317e;
    color: white;
    border-radius: 0px;
    margin: 0px;
    padding: 0 1rem;
  }
}
