@import '/src/mixins';

.history-merged-points {
  background-color: 'primary.light';
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}

.history-merged-points-results {
  height: 20rem;
  overflow-y: auto;
  resize: vertical;
  scrollbar-width: thin;
}

.history-merged-point-result {
  background-color: 'primary.main';
  margin-bottom: 10px;
  padding: 0.5rem 2.5rem 0.5rem 0.5rem;
  cursor: pointer;

  &.selected {
    background-color: 'primary.light';
  }
}

.history-merged-points-stats,
.history-merged-points-results,
.history-merged-points-input-area,
.history-merged-points-message.gap-length-label,
.history-ais-merged-point-clear-selection {
  margin-top: 10px;
}

.history-merged-points-popup {
  text-align: left;
}

.points-count-label,
.hours-label {
  margin-left: 10px;
}
