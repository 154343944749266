@import '/src/mixins';
@import '../../z-indexes.scss';

.hidden {
  display: none;
}

#side-menu {
  width: 5rem;
  overflow-x: hidden;
  position: relative;
  overflow-y: auto;
  scrollbar-width: thin;
  z-index: $--menu-layer;

  &.mobile {
    overflow-y: hidden;
  }
}

.side-menu-item {
  display: flex !important;
  border-radius: 1rem !important;
}

.menu-item {
  text-align: left;
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  font-weight: 500 !important;
}

.side-menu-open {
  animation: side-menu-slide-open 0.25s linear 0s 1 normal forwards;
  pointer-events: all;

  &.mobile {
    animation: side-menu-slide-open-mobile 0.25s linear 0s 1 normal forwards;
    border: none;
  }
}

.menu-container {
  &.mobile {
    pointer-events: none;
  }
}

.side-menu-closed {
  animation: side-menu-slide-close 0.25s linear 0s 1 normal forwards;

  &.mobile {
    animation: side-menu-slide-close-mobile 0.25s linear 0s 1 normal forwards;
    border: none;

    & > :first-child {
      display: flex;
      pointer-events: all;
    }
    & > * {
      display: none;
    }
    background: none;
  }
}

.side-menu-closed .side-menu-item {
  animation: side-menu-item-closed 0.25s linear 0s 1 normal forwards;
}

.side-menu-open .side-menu-item {
  animation: side-menu-item-open 0.25s linear 0s 1 normal forwards;
}

@keyframes side-menu-item-open {
  0% {
    border-radius: 16px;
  }
  100% {
    border-radius: 100px;
  }
}

@keyframes side-menu-item-closed {
  0% {
    border-radius: 100px;
  }
  100% {
    border-radius: 16px;
  }
}

@keyframes side-menu-slide-open {
  0% {
    width: 5rem;
  }

  100% {
    width: 20rem;
  }
}

@keyframes side-menu-slide-close {
  0% {
    width: 20rem;
  }

  100% {
    width: 5rem;
  }
}

@keyframes side-menu-slide-open-mobile {
  0% {
    width: 5rem;
  }
  100% {
    width: 100vw;
  }
}
