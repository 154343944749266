@import '/src/mixins';

#route-upload-label {
  background-color: 'secondary.main';
  color: 'primary.main';

  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.route-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  padding: 1rem;
  color: 'text.primary';
  scrollbar-width: thin;

  .form-button {
    background-color: 'secondary.main';
    color: 'primary.main';
    border: none;
    border-radius: 0.3rem;
  }

  .route-upload-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    gap: 0.5rem;

    margin: 0 0 1rem 0;
    .form-error-message {
      padding: 0.5rem 0.4rem;
      border-radius: 0.3rem;
    }
    .draw-route-button {
      padding: 0.3rem;
      border-radius: 0.3rem;
    }
  }
  .lat-long-container {
    padding-bottom: 0.5rem;
    width: 100%;
    display: grid;
    text-align: start;
    &.scroll {
      grid-template-columns: 10fr 10fr 1fr;
      max-height: 29vh;
      overflow-y: auto;
      scroll-snap-type: y mandatory;
      scrollbar-width: thin;
    }

    grid-template-columns: 4fr 4fr 1fr;
    column-gap: 0.5rem;
    row-gap: 0.6rem;
    .lat-long-container {
      grid-column: 1/5;
      padding-bottom: 0;
      border-top: none;
    }
    .text-field-group {
      margin: 0;
    }
    .title {
      grid-column: 1/5;
    }
    .latitude {
      grid-column: 1;
    }
    .longitude {
      grid-column: 2;
    }
    .remove {
      grid-column: 3;
      background: transparent;
      border-color: transparent;
      color: 'text.primary';
      &:hover {
        color: 'text.secondary';
      }
    }
    .waypoints-error-message {
      grid-column: 1/5;
    }
    .add {
      grid-column: 1/5;
    }
  }

  .route-radius-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    input {
      width: 90%;
    }
  }

  button[type='submit'] {
    width: 100%;
  }
}
