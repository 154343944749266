@import '/src/mixins';

.vessel-information-container {
  background-color: 'primary.main';
  text-align: left;
  max-height: 75vh;
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 0.25rem;

  .vessel-information {
    color: 'text.primary';
    margin-left: 1.5rem;
  }

  .additional-vessel-information {
    margin: 1rem;
    color: 'text.primary';
  }
  h3 {
    color: 'text.primary';
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.alert-changed {
  border: 1px solid red;
  padding: 2px;
}
