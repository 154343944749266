@import '/src/mixins';

.boundaries-panel-results-container {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 15.5rem); // filters + title + dividers = ~15rem
  padding-bottom: 1rem; // padding for Accordion paper effect
  scrollbar-width: thin;
}

.boundaries-panel-container {
  height: 98%;
  flex-direction: column;
  display: flex;
}

.boundaries-panel {
  flex-direction: column;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 96vh;
}

.boundaries-panel-results-list {
  max-height: 67vh;
  overflow-y: auto;
  scrollbar-width: thin;
}
