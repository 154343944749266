@import '/src/mixins';
@import '/src/z-indexes.scss';

.user-menu-container {
  z-index: $--map-overlay-layer;
  position: fixed;
  top: 0;
  right: 0;
  margin: 1rem;

  text-align: right;

  .user-menu-open {
    background-color: 'secondary.main';
  }

  .user-menu-closed {
    background-color: 'secondary.main';
  }

  .user-button {
    border: none;
    border-radius: 50%;

    &:hover {
      background-color: 'secondary.dark';
    }

    .user-button-menu-open {
      background-color: 'secondary.dark';
    }

    .user-button-icon {
      &.mobile {
        height: 30px;
      }
      padding: 0.5rem;
      color: 'primary.main';
    }
  }
}
