.draw-tips {
  margin-left: 15px;

  .mapboxgl-popup-content {
    background-color: rgba(0, 0, 0, 0.3) !important;
    padding: 0 !important;
    color: white;
    padding: 0.5em !important;
    border-radius: 6px !important;
    pointer-events: none !important;
  }

  .mapboxgl-popup-anchor-top,
  .mapboxgl-popup-tip {
    border-bottom-color: rgba(0, 0, 0, 0.3) !important;
    display: 0 !important;
    &::before {
      display: none;
    }
  }
}
