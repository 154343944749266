@import '/src/mixins';

.heatmap-container {
  padding-left: 0.5rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.heatmap-key {
  height: 40px;
  width: 100%;
  border-radius: 10px;
}

.heatmap-key-label {
  text-align: center;
  margin-top: 10px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
