@import '/src/mixins';
@import '../../z-indexes.scss';

.apply-setup-bar-container {
  background-color: #4bfa91;
  padding: 0.5rem;
  display: flex;
  position: absolute;
  border-radius: 0.5rem;
  z-index: $--top-layer;
  top: 1.5rem;
  right: 7rem;
  animation: apply-setup-bar-fade-in 1s;
  overflow: hidden;

  &.fade-out {
    animation: apply-setup-bar-fade-out 1s;
  }

  .apply-setup-bar-icon {
    color: #231f20;
  }

  .apply-setup-bar-container-message {
    color: #231f20;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    text-align: left;
    min-width: 16.5rem;
  }
}

@keyframes apply-setup-bar-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes apply-setup-bar-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
