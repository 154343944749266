@import '/src/mixins';

.portsList {
  list-style: none;
  padding: 0;
  overflow-y: auto;
  height: calc(100vh - 23rem);
  flex-grow: 1;
  margin-top: 0.5em;
  margin-bottom: 0;
  transition: height 0.5s ease-out;
  transition-delay: 0.3s;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

.portListTitle {
  margin: 0;
  margin-left: 0.3em;
  text-align: left;
  font-size: 1.17em;
  color: 'text.primary';
  flex: 8;
}

.portListTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
