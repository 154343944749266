@import '/src/mixins';

// If we can figure out how to convert the RI description into MUI Typography/Link elements
// without accidentally writing a html parser, we can remove this
.specificIncidentDescription {
  text-align: left;
  margin: 6px;

  p {
    margin: 6px 0;
  }

  a.riAnchor {
    color: 'secondary.dark';
    filter: brightness(1.4);

    &:hover,
    &:active,
    &:focus {
      color: 'secondary.dark';
      filter: brightness(1.6);
    }
  }
}
