.upload-attachments-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}

.attachments-list {
  margin-top: 10px;
}

.upload-attachments-form-supported-files {
  text-align: left;
  margin-top: 20px;

  .files-list {
    font-weight: bold;
    white-space: pre-line;
  }
}
