.create-region-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 31vw;
  &.mobile {
    width: 100%;
    padding: 0;
    .polygon-points {
      overflow-y: unset;
      height: unset;
    }
    .react-tabs__tab-list {
      margin-left: 0;
      margin-right: 0;
    }
    .react-tabs__tab-panel {
      margin: 0;
    }
  }
}

.create-region-fields {
  width: 90%;

  h3 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: left;
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.create-region-submit-button {
  background-color: green;
  color: white;
  border-color: transparent;
  border-radius: 10px;
  margin-top: 1rem;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 128, 0, 0.589);
  }
}

.create-region-modal-loading-panel {
  padding: 0.7rem;
  display: flex;
  align-items: center;
  background-color: rgb(144, 222, 255);
  .loading-panel-message {
    padding-left: 0.5rem;
    color: rgb(60, 60, 148);
  }
  .loading-panel-spinner {
    padding-left: 0.5rem;
    margin-bottom: -0.4rem;
  }
}

.create-region-modal-error-panel {
  padding: 0.7rem;
  display: flex;
  align-items: center;
  background-color: rgb(255, 169, 169);
  .error-panel-message {
    padding-left: 0.5rem;
    color: darkred;
  }
  .error-panel-icon {
    padding-left: 0.5rem;
    color: red;
  }
}

.create-region-modal-success-panel {
  padding: 0.7rem;
  display: flex;
  align-items: center;
  background-color: rgb(188, 255, 169);
  .success-panel-message {
    padding-left: 0.5rem;
    color: darkgreen;
  }
  .success-panel-icon {
    padding-left: 0.5rem;
    color: green;
  }
}
