.threat-assessment-chip {
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: small;
}

.threat-chip {
  margin: 0 0.5rem;
  border-radius: 50%;
  width: 0.75rem;
  height: 0.75rem;
}
