@import '/src/mixins';

.port-filter-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;

  margin-bottom: 1rem;
  padding-top: 2rem;
  background-color: white;
  border-radius: 10px;
  border: solid;
  border-width: 3px;

  &:focus-within {
    border-color: 'secondary.dark' !important;
  }

  .port-filter-delete-button {
    grid-column: span 2;
    justify-self: flex-end;
    background-color: white;
    border: none;
    border-radius: 3px;

    &:hover {
      background-color: 'secondary.dark';
    }
  }

  .port-filter {
    width: 95%;
    height: 1.5rem;
    border: none;
    margin-left: 0.5rem;

    &:focus {
      outline: none;
    }

    // margin-top: 0.2rem;
  }
}
