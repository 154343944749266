.history-results-export-form {
  padding: 0.5rem;
  text-align: left;
}

.history-results-export-controls {
  margin-top: 20px;
  display: flow-root;
}

.history-results-export-button {
  float: right;
}

.history-results-export-input-section {
  margin-top: 10px;
}

.history-results-export-input-field {
  width: 100%;
}

input.history-results-export-input-field[type='text'] {
  width: 98%;
}

.history-results-export-modal {
  width: 30vw;
}
