@import '/src/mixins';

.error-panel {
  display: flex;
  justify-content: center;

  ul {
    list-style: none;
    padding-left: 0;
  }

  li {
    text-align: center;
  }

  .error-panel-icon {
    font-size: 100px;
  }
}
