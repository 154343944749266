@import 'src/mixins';

.breadcrumb-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: 'primary.light';

  :first-child {
    padding-left: 1em;
  }

  .back-splitter {
    padding: 0 5px;
    &:first-child {
      padding-left: 1em;
    }
  }

  .breadcrumb-item {
    &.breadcrumb-ellipsis {
      text-decoration: none;
    }
    &:first-child {
      padding-left: 1em;
    }
    color: 'text.primary';
    border: none;
    background: transparent;
    padding: 0;
    text-decoration: underline;
  }
}
