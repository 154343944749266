@import '/src/mixins';

.specific-port-header {
  display: flex;
  margin: 0.5rem 1rem;
}

.specific-port-title {
  text-align: left;

  .specific-port-country {
    padding: 0;
    font-size: 0.875rem;
    justify-content: start;
    font-weight: 400;
    border: none;
    margin: 0;
    padding: 0;
    text-decoration: underline;
    background: none;
    &:hover,
    &:active,
    &:focus {
      filter: brightness(1.6);
      background-color: unset;
    }
  }

  .specific-port-back {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: 'secondary.dark';
    padding: 3px;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      color: 'secondary.dark';
      filter: brightness(1.3);
    }
  }
}
