@import '/src/mixins';

@keyframes inAnimationTA {
  0% {
    width: 0;
    // visibility: hidden;
  }
  100% {
    width: 30vw;
    // visibility: visible;
  }
}

@keyframes inAnimationTAMobile {
  0% {
    width: 0;
    // visibility: hidden;
  }
  100% {
    width: 100vw;
    // visibility: visible;
  }
}

@keyframes outAnimationTA {
  0% {
    width: 30vw;
  }
  100% {
    width: 0;
    visibility: hidden;
  }
}

@keyframes outAnimationTAMobile {
  0% {
    width: 100vw;
  }
  100% {
    width: 0;
    visibility: hidden;
  }
}

.pdf-download-button {
  color: black;
  a.pdf-download-link {
    color: black;
  }
  &:hover {
    color: white;
    a.pdf-download-link {
      color: white;
    }
  }
}
