@import '/src/mixins';
@import '../../z-indexes.scss';

$secondary-pane-width: 25vw;

.secondary-pane-container {
  pointer-events: all;
  min-width: $secondary-pane-width;
  &.mobile {
    --bottomdraw-height: 55%;
    width: 100vw;
    min-width: 100vw;
    position: fixed;
    left: 0;
    z-index: $--feature-panel-layer;
    .secondary-pane-container-content {
      min-width: 100vw;
    }
    top: calc(100% - var(--bottomdraw-height));
  }
}

.secondary-pane-button-container {
  top: 0;
  right: 0;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 3.35rem;
  z-index: 1;
}

.toggle-and-pane-button-container {
  top: 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -3.35rem;
  height: 3.35rem;
}

.icons {
  background-color: transparent;
  border-color: transparent;
  border-radius: 20%;
  margin: 0.5rem;
  z-index: $--feature-panel-contents-layer;
  position: relative;
  top: 0.8rem;
  right: 0;

  .close-panel-icon {
    color: 'secondary.main';
  }
}

.no-display {
  display: none;
}

@keyframes inAnimation {
  0% {
    width: 0;
    // visibility: hidden;
  }

  100% {
    width: $secondary-pane-width;
    // visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    width: $secondary-pane-width;
  }

  100% {
    width: 0;
    visibility: hidden;
  }
}
