@import '/src/mixins';

.ri-tab-content {
  overflow-y: auto;
  display: flex;
  scrollbar-width: thin;
  flex-direction: column;
  max-height: calc(100vh - 170px);
  border-radius: 0 0 1rem 1rem;

  .accordion-title {
    color: 'text.primary';
  }

  .region-documents-button {
    justify-content: space-between;
    padding: 0.5rem 1rem;
    font-weight: 400;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  a {
    overflow-wrap: anywhere;
  }

  a:visited {
    color: rgb(170, 40, 240);
  }

  table {
    border: none;
    border-spacing: 0;
  }

  td {
    padding: 0.2rem 0;
    border: none;
    text-align: left;
    color: 'text.primary';
  }

  .accordion-content {
    background-color: unset;
    padding: 0;
  }

  .details-buttons {
    display: flex;
    justify-content: space-evenly;
    margin: 0 8%;

    .details-button {
      display: flex;
      flex-direction: column;
      font-size: 0.7rem;
      width: 25%;
      font-weight: 400;
      padding: 6px 8px;
      align-items: center;

      &:hover {
        background-color: unset;
      }

      .details-button-icon {
        padding: 0.45rem;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
}
