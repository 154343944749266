@import '/src/mixins';

.incidentTicker {
  position: fixed !important;
  right: 0;
  bottom: 0;
  height: var(--news-ticker-height);
  color: white;
  background-color: 'primary.main';
  width: 100%;
  overflow-y: hidden;
  .overlay {
    pointer-events: none;
    touch-action: none;
  }
}

.incidentTickerItem {
  border: none;
  padding: 1rem 2rem;
  text-decoration: underline;
  cursor: pointer;
  color: white;
  background-color: 'primary.main';
  &:hover {
    color: yellow;
  }
}
