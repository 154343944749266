#map-container {
  height: 100%;
  width: auto;

  // Move map and map controls upwards when Ticker is open
  &.tickerOpen {
    height: calc(100% - 2.65em);

    & .mapboxgl-ctrl-bottom-right {
      bottom: 0.35em;
    }
  }
  &.boundary-select {
    .mapboxgl-canvas-container {
      cursor: crosshair !important;
    }
  }
}
