@import '/src/mixins';

.vessel-dossier-container {
  .dossier-title {
    display: inline-flex;
    text-align: left;
    width: 100%;
    margin-left: 1rem;

    h3 {
      margin-left: 1rem;
      color: 'secondary.main';
    }

    h4 {
      color: 'text.primary';
    }

    button {
      background-color: transparent;
      border: none;
      line-height: 0;
      padding: 2px;
      margin-top: 1rem;
      position: absolute;
      right: 0;
      margin-right: 1rem;

      &:hover {
        background-color: 'secondary.dark';
      }
      .close-panel-icon {
        color: 'secondary.main';
      }
    }
  }
}

.vessel-dossier-options {
  margin: 2rem;
  display: flex;
  justify-content: right;

  .see-vessel-history-button {
    background-color: 'secondary.main';
    border-color: transparent;
    border-radius: 10px;
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    color: 'text.primary';

    .see-history-icon {
      padding-right: 0.5rem;
      margin: 0;
    }
    p {
      margin: 0;
    }

    &:hover {
      background-color: 'secondary.dark';
      color: 'text.primary';
    }
  }
}

@keyframes inAnimationVesselDossier {
  0% {
    width: 0;
    // visibility: hidden;
  }
  100% {
    width: 30vw;
    // visibility: visible;
  }
}

@keyframes inAnimationVesselDossierMobile {
  0% {
    width: 0;
    // visibility: hidden;
  }
  100% {
    width: 100vw;
    // visibility: visible;
  }
}

@keyframes outAnimationVesselDossier {
  0% {
    width: 30vw;
  }
  100% {
    width: 0;
    visibility: hidden;
  }
}

@keyframes outAnimationVesselDossierMobile {
  0% {
    width: 100vw;
  }
  100% {
    width: 0;
    visibility: hidden;
  }
}
