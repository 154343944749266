.search-results-container {
  max-height: 75vh;
  overflow-y: auto;
  scrollbar-width: thin;

  .search-result-item-card {
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    justify-content: space-between;
    .search-result-item-title {
      padding: 0.5rem 0;
    }
  }
}

.close-search-tab-button {
  width: 100%;
}
