@import '/src/mixins';

// amplify-visually-hidden has postion:absolute, which causes the <li> elements to be rendered
// even if they are within an element with overflow:auto
span.amplify-visually-hidden {
  position: unset;
}
// unsetting position:absolute causes the width to adjust exactly to visible elements,
// which causes a 1px left-to-right scrollbar to appear.
// set overflow:hidden to fix (it will only ever be hiding invisible elements)
label.amplify-checkbox {
  overflow-x: hidden;
  padding-left: 2px;
}

span.amplify-text {
  color: 'text.primary' !important;
}

.listPickerList {
  list-style: none;
}

.listPickerAllNoneButtons {
  display: flex;
  margin: 0;
  & :first-child {
    margin-right: 2px;
  }
  & :last-child {
    margin-left: 2px;
  }
}

.listPickerButton {
  inline-size: stretch;
  border: none;
  border-radius: 3px;
}

.listPickerSubmit {
  margin: auto;
}

.popoverContainer {
  filter: drop-shadow(0 0.2rem 0.3rem rgba(0, 0, 0, 0.5));
}
