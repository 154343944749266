@import '/src/mixins';

.download-incidents-button {
  border: none;
  background-color: 'primary.main';
  &:hover {
    background-color: 'secondary.dark';
  }
  color: 'text.primary';
  border-radius: 20px;
  border: 1px solid 'primary.main';

  a {
    width: 100%;
    text-decoration: none;
  }
}

.download-incidents-button-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin: 0.5em;
  margin-bottom: 1rem;

  .download-view-on-buttons-download {
    border: none;
    background-color: green;
    border-radius: 20px;
    color: white;

    &:hover {
      background-color: rgba(1, 180, 1, 0.774);
    }
  }
}
