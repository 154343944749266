@import '/src/mixins';

.search-filter-container {
  display: grid;
  padding: 0.5rem;
  grid-template-columns: 5fr 0.5fr;
  margin-bottom: 1rem;
  background-color: white;
}

.search-filter {
  padding: 1em;
  width: 95%;
  height: 1.5rem;
  border: none;
  margin-left: 0.5rem;
  &:focus {
    outline: none;
  }
  // margin-top: 0.2rem;
}
