@import '/src/mixins';

@keyframes inAnimationCountryDossier {
  0% {
    width: 0;
    // visibility: hidden;
  }
  100% {
    width: 30vw;
    // visibility: visible;
  }
}

@keyframes inAnimationCountryDossierMobile {
  0% {
    width: 0;
    // visibility: hidden;
  }
  100% {
    width: 100vw;
    // visibility: visible;
  }
}

@keyframes outAnimationCountryDossier {
  0% {
    width: 30vw;
  }
  100% {
    width: 0;
    visibility: hidden;
  }
}

@keyframes outAnimationCountryDossierMobile {
  0% {
    width: 100vw;
  }
  100% {
    width: 0;
    visibility: hidden;
  }
}
