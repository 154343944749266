#map-details-indicator-container {
  background-color: #3131317e;
  position: fixed;
  bottom: 2px;
  left: 50%;
  width: 14rem;
  height: 2.5rem;
  transform: translate(-50%, 0);
  border-radius: 12px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &.tickerOpen {
    bottom: 34px;
  }
}
