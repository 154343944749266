@import 'src/mixins';

.maritime-area-info-card-container {
  height: auto;
  margin: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  span {
    width: 80%;
    text-align: left;
  }
  &:hover,
  &:active,
  &:focus {
    background-color: 'primary.light';
    cursor: pointer;
  }
}
