:root {
  --news-ticker-height: 2rem;
}

html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Titillium Web', sans-serif;
}
