@import '/src/mixins';

.sanctionItem {
  display: flex;
  width: 99%;
  align-items: center;
  margin-bottom: 5px;
  background-color: 'primary.main';
  border-radius: 3px;
  padding: 5px;
  border-style: none;
  transition: background-color ease-in-out 0.1s;

  .sanctionDate {
    text-align: left;
    margin: 0.5em;
    padding-right: 1em;
    font-size: 0.7em;
    text-align: left;
    color: 'text.secondary';
    font-weight: 800;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: 'primary.light';
    cursor: pointer;
  }
}

.sanctionLink {
  text-align: left;
  margin: 0.5em;
  font-size: 0.7em;
  text-align: left;
  color: 'text.secondary';
}

.sanctionItem button {
  border-color: transparent;
  border-radius: 20px;
  font-size: small;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-top: 5px;

  &:hover {
    background-color: 'secondary.dark';
  }
}

.expandButton {
  background-color: 'secondary.main';
  color: 'primary.main';
  float: right;
}

.collapseButton {
  background-color: 'secondary.main';
  color: 'primary.main';
  float: right;
}

.openLinkButton {
  background-color: 'secondary.main';
  color: 'primary.main';
  float: left;
}

.sanctionContent {
  flex: 5;
}

.sanctionTitle {
  color: 'text.primary';
  font-weight: bold;
  text-align: left;
  margin: 0;
  font-size: 0.8em;
  clear: both;
}

.sanctionDescription {
  flex: 1;
  word-wrap: break-word;
  color: 'text.primary';
  text-align: left;
  line-height: 1.4em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: horizontal;
  font-size: 0.8em;
  margin: 0;
  min-width: 0;
}
