@import '/src/mixins';

.ri-details {
  border-radius: 1rem;
  overflow-y: auto;
  scrollbar-width: thin;
  max-height: 90vh;

  .flag-content {
    border-radius: 0;
    width: 100%;
    padding: 0.5rem 1rem;
    font-weight: 400;
  }

  table {
    border: none;
    border-spacing: 0;
  }

  td {
    border: none;
    text-align: left;
    color: 'text.primary';
    padding: 0.2rem 0.75rem;
  }

  .address {
    line-height: 1.2rem;
    padding: 0 0.75rem;
    text-align: right;
  }

  .accordion-content {
    background-color: unset;
    padding: 0;
    box-shadow: unset;
    margin: 0;
  }

  .accordion-summary {
    height: 20px;
    margin: 0;
  }
}
