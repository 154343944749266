.albumImageButton {
  border: none;
  background-color: transparent;
  padding: 0;
  position: relative;

  &.text {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 9rem;
    height: 150px;
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;

    span {
      color: white;
      margin: 20px;
      text-decoration: underline;
      position: absolute;
      bottom: 0;
    }
  }
}

.albumImagesContainer {
  display: flex;
  & > * {
    flex: 1;
  }
  flex-direction: row;
}

.albumSmallImage {
  width: 100%;
  height: 150px;
  min-height: 9rem;
  object-fit: cover;
}

.yarl__slide_image {
  height: 100%;
}
