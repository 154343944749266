@import '/src/mixins';
@import '../../z-indexes.scss';

.drawing-panel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: $--feature-panel-contents-layer;
  transition: background-color 0.3s ease-in-out;
  color: white;
  font-size: large;
  display: flex;
  border: none;

  .drawing-panel-overlay__content {
    position: relative;
    margin: 1rem 1rem 1rem 3rem;
    top: 10%;
    transform: translateY(-10%);
  }
}
