@import '/src/mixins';

.save-setup-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 1rem;
}

.confirm-save-setup-container {
  padding-bottom: 1rem;
}

.confirm-save-setup-button {
  background-color: green;
  color: white;
  border-color: transparent;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 128, 0, 0.589);
  }
}

.error-message {
  color: red;
}
