@import '/src/mixins';

.routes-panel-results-container {
  padding: 0 0.5rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
}

.routes-panel-container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.routes-panel {
  flex-direction: column;
  height: 100%;
}

.routes-panel-results-list {
  overflow-y: auto;
  scrollbar-width: thin;
  box-sizing: border-box;
  height: 100%;
}

.routes-panel-warning-message {
  color: 'text.primary';
}
