.delete-confirmation-modal {
  width: 30vw;
}

.delete-items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  //   margin: 1rem;
  margin-bottom: 1rem;

  .delete-items-message {
    margin-top: 1rem;
  }
}

.delete-confirmation-button {
  background-color: red;
  border-color: transparent;
  border-radius: 10px;

  &:hover {
    background-color: #ff000093;
  }
}

.deleting-banner {
  width: 100%;
}
