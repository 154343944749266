@import '/src/mixins';

.ri-country-panel {
  overflow-y: auto;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 170px);
  color: 'text.primary';

  table {
    border: none;
    border-spacing: 0;
  }
  td {
    border: none;
    text-align: left;
    color: white;
  }

  .country-summary {
    text-align: left;
    padding: 0.5rem;
  }

  .ri-documents-panel-container {
    padding: 0.5rem;
  }
}

.button-bgcolor {
  background-color: transparent !important;
  padding: 0 !important;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  color: black;
}

.button-bgcolor:hover {
  background-color: transparent !important;
  cursor: pointer;
}
