@import '/src/mixins';

.ri-documents-dropdown-panel {
  .ri-documents-dropdown-header {
    font-weight: 500;
    text-align: left;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    border-bottom: solid 1px 'primary.light';
  }

  .ri-documents-controls {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
  }

  .ri-documents-list {
    margin: 15px 0px;
  }
}
