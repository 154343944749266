@import '/src/mixins';

.history-panel-label {
  width: 4rem;
  display: inline-block;
  padding-right: 2rem;
  text-align: right;
}

.history-panel-filters {
  text-align: left;
  margin: 1rem;
}
.error-chip {
  border-color: red;
  border-style: solid;
  border-width: 2px;
}

.form-input-chip {
  inline-size: stretch;
  border: none;
  background-color: 'primary.main';
  color: 'text.primary';
  text-align: center;

  &:focus-within {
    border-color: 'text.primary';
  }

  &:hover {
    background-color: 'secondary.dark';
  }

  input,
  select {
    background-color: transparent;
    color: 'text.primary';
    border: none;
    font-size: 16px;
    text-align: center;
    height: 100%;
    width: 100%;
    padding: 0;
    cursor: pointer;

    &:hover {
      background-color: 'primary.main';
    }

    option {
      color: 'primary.main';
    }
  }
}

.history-panel-container-buttons {
  margin: 1rem;
  display: flex;
  justify-content: right;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  .history-panel-get-button {
    background-color: green;
    color: white;
    border-color: transparent;
    border-radius: 10px;
    height: 2rem;
    width: 7rem;
    font-weight: bold;
    font-size: 14px;
    &:hover {
      background-color: 'secondary.dark';
    }
  }
}

.error-messages {
  text-align: center;
  color: red;
}

.history-panel-identifiers {
  margin: 1rem;

  span {
    font-size: 12px;
  }

  textarea {
    width: 100%;
    resize: none;
    font-size: 16px;
  }
}
