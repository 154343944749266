@import '/src/mixins';

.attachment-list-item {
  text-align: left;
  margin: 2px;
  border: 2px solid 'background.default';
  padding: 4px;
  border-radius: 3px;
  display: flex;
}

.attachment-list-item-file-name {
  margin-left: 10px;
  width: 100%;
}

.attachment-list-item-remove-item {
  cursor: pointer;
  margin-right: 5px;
}
