@import '/src/mixins';

.upload-entity-documents-modal-container {
  input,
  select {
    background-color: white !important;
  }

  padding: 1rem;

  .error-message {
    margin-top: 10px;
    color: red;

    white-space: pre-line;
  }
}

.upload-entity-documents-info-form {
  margin-top: 20px;
}

.upload-entity-documents-form {
  padding: 1rem;

  display: flex;
  flex-direction: column;
  text-align: left;
}

.upload-entity-documents-modal {
  width: 40%;
  &.mobile-modal {
    width: unset;
  }
}

.upload-entity-documents-additional-form-content {
  margin: 20px 0px;
}

.upload-entity-documents-modal-form-section {
  display: flex;

  & .upload-entity-documents-modal-form-label {
    width: 25%;
    text-align: left;
  }
}

.upload-entity-documents-submit-section {
  text-align: right;
  margin-top: 10px;
}

.upload-entity-documents-submit-button {
  background-color: green;
  color: white;
  border-color: transparent;
  border-radius: 10px;
  margin-top: 1rem;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 128, 0, 0.589);
  }
}

.upload-entity-documents-form-text-field,
.select-upload-entity-documents-dropdown {
  width: 50% !important;
}

.select-upload-entity-documents-label {
  text-align: left;
}
