@import 'themes';
@import './z-indexes.scss';

.App {
  text-align: center;
  overflow: hidden;
}

.menu-container {
  position: absolute;
  z-index: $--menu-layer;
  height: 100%;

  &.hidden {
    visibility: hidden;
    pointer-events: none;
    touch-action: none;
  }
}

.app-container {
  width: 100%;
  height: 100%;
}

.company-logo {
  position: fixed;
  margin-top: 1rem;
  top: 0;
  left: 50%;
  z-index: $--map-overlay-layer;
  transform: translate(-50%, 0);
  height: 4rem;
  &.mobile {
    height: calc(1rem + 35px);
  }

  :root[data-theme='RISK_INTELLIGENCE'] & {
    height: 2rem;
    margin-top: 2rem;
    &.mobile {
      width: calc(100% - 160px);
      max-width: max-content;
      max-height: -webkit-max-content;
      max-height: 2rem;
      margin-top: 1.5rem;
      object-fit: contain;
    }
  }
}

button {
  cursor: pointer;
}

.mapboxgl-ctrl button:disabled,
.mapboxgl-ctrl button:disabled:hover,
button[disabled]:not(.Mui-disabled) {
  background-color: #dbdbd9 !important;
  cursor: pointer;
}

// change default styles for mapbox control buttons
.mapboxgl-ctrl-group {
  button {
    &:first-child {
      border-top-left-radius: 12px !important;
      border-top-right-radius: 12px !important;
    }
    &:last-child {
      border-bottom-left-radius: 12px !important;
      border-bottom-right-radius: 12px !important;
    }
    &:not(:first-child) {
      border-top: none !important;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 1px;
        width: 80%;
        border-bottom: 1px solid black;
      }
    }
    background-color: #4bfa91 !important;
    color: 'primary.main' !important;
    width: 36px !important;
    height: 36px !important;
  }
  border-radius: 12px !important;
}

@media only screen and (max-width: 750px) {
  .mapboxgl-ctrl-group button {
    width: 28px !important;
    height: 28px !important;
  }
}

.mapboxgl-ctrl-group button:hover {
  background-color: #4bbf7c !important;
}

.mapboxgl-ctrl-group button + button {
  border-top: 1px solid black !important;
}

.mapboxgl-ctrl-home {
  background-image: url('/assets/icons/map-controls/home-solid-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}

.custom-mapbox-icon {
  fill: #eee;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.mapboxgl-popup {
  animation: fadein 0.2s;
}

.mapboxgl-popup-content {
  pointer-events: none;
  color: #000;
}

.mapboxgl-popup-properties {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.mapboxgl-popup-name {
  font-family: 'Titillium Web';
  text-align: left;
  font-size: 1rem;
  padding-bottom: 0.2rem;
}
.mapboxgl-popup-tip {
  --tip-offset: 20px;
  height: var(--tip-offset) !important;
  border: unset !important;
  width: 0;
  display: block !important;
  &:before {
    content: '';
    position: absolute;
    transform: translateX(-50%);
    border-width: var(--tip-offset) 6px; /* Adjust these values to control the aspect ratio */
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
}

.mapboxgl-popup-property {
  font-size: 0.75rem;
  padding-right: 0.5rem;
  font-family: 'Titillium Web';
  color: #454a4f;
  text-align: left;
}

.mapboxgl-popup-content-header {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
  pointer-events: none;
}

.mapbox-gl-latlng_ctrl-btn {
  background-image: url('/assets/icons/map-controls/pin-map.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 45%;
}

.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_circle {
  background-image: url('/assets/icons/map-controls/circle.svg') !important;
  background-size: 40%;
}

.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line {
  background-image: url('/assets/icons/map-controls/polyline.svg') !important;
  background-size: 70%;
}

.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon {
  background-image: url('/assets/icons/map-controls/polygon.svg') !important;
  background-size: 80%;
}

.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line_lhs {
  background-image: url('/assets/icons/map-controls/mapbox-ctrl-icon-draw-line-white.svg');
  background-size: 100%;
}

:root[data-theme='RISK_INTELLIGENCE'] {
  .mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line_lhs {
    background-image: url('/assets/icons/map-controls/mapbox-ctrl-icon-draw-line-black.svg');
    background-size: 100%;
  }

  .mapboxgl-ctrl-group {
    .mapboxgl-ctrl-home {
      background-image: url('/assets/icons/map-controls/ri-home-extent.svg');
      background-size: 60%;
    }
    .mapboxgl-ctrl-zoom-in {
      > .mapboxgl-ctrl-icon {
        background-image: url('/assets/icons/map-controls/ri-zoom-in.svg');
      }
    }
    .mapboxgl-ctrl-zoom-out {
      > .mapboxgl-ctrl-icon {
        background-image: url('/assets/icons/map-controls/ri-zoom-out.svg');
        background-size: 80%;
      }
    }
    button {
      background-color: #fafdff !important;
      color: 'secondary.main' !important;
      &:hover {
        background-color: #d7dada !important;
      }
    }
  }
}

.mapboxgl-popup-content {
  pointer-events: none;
}

.popoverContainer {
  border-radius: 3px;
  background-color: 'primary.main';
  display: flex;
  flex-direction: column;
  width: max-content;
  min-height: 100px;
  min-width: 250px;
  z-index: $--map-overlay-layer;
  max-height: 95vh;
  overflow: auto;
  scrollbar-width: thin;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  right: 2px;
  background: 'primary.dark';
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: 'secondary.dark';
  border-radius: 3px;
}

::-webkit-scrollbar {
  width: 10px;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    border-bottom: 1px solid 'primary.light';
    padding: 0;
    margin: 0;
    text-align: left;
    display: flex;
  }

  &__tab {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    width: 50%;
    font-size: 0.75rem;
    list-style: none;
    border-radius: 5px 5px 0 0;
    min-height: 45px;

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }

    color: 'text.primary';
    cursor: pointer;

    &--selected {
      // background: 'primary.light';
      // border-color: transparent;
      // color: black;
      // border-radius: 5px 5px 0 0;
      border-bottom: 2px solid 'primary.dark';
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      outline: none;

      &:after {
        content: '';
        position: absolute;
        height: 5px;
        left: -2px;
        right: -2px;
        bottom: -5px;
      }
    }
  }

  &__tab-panel {
    display: none;
    height: 100%;
    border-radius: 0 0 1rem 1rem;

    &--selected {
      display: block;
    }
  }
}

.filter-button.mobile {
  min-height: 2rem;
}

.download-ri-report-button {
  background-color: green;
  border: none;
  border-radius: 10px;
  color: white;
  width: 95%;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  a {
    width: 100%;
    color: white;
    text-decoration: none;
    padding-right: 2rem;
  }

  &:hover {
    background-color: 'secondary.dark';
    cursor: pointer;
  }

  span {
    margin-right: 1rem;
  }
}

.svg-inline--fa.fa-circle {
  path {
    stroke: currentColor;
    stroke-width: 20;
  }
}

.apply-setup-bar-container.mobile {
  left: 10px;
  right: auto;
  top: 80px;
}

.dykContent p {
  margin: 0.5em 0;
  font-size: 0.95 rem;
}

.MuiBox-root {
  scrollbar-width: thin;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
