@import '/src/mixins';

.incidentItemli {
  display: flex;
  flex-direction: row;

  .incident-item-checkbox {
    height: auto;
    display: flex;
    align-items: center;
    width: 1.5rem;
    transition: width ease-in-out 0.2s, margin ease-in-out 0.2s,
      visibility ease-in-out 0.2s;
    margin: 0 0.5rem;
    input {
      height: 1.5rem;
      width: 1.5rem;
    }
    &.visible {
      width: 1.5rem;
      visibility: visible;
      margin: 0 0.5rem;
    }
    &.hidden {
      width: 0;
      visibility: hidden;
      margin: 0;
    }
  }
}

.incidentItem.selected {
  animation: pulse 1s 5;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    background-color: 'primary.light';
    font-weight: bold;
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    background-color: 'primary.light';
    font-weight: bold;
  }
}

.incidentItem {
  display: flex;
  width: 99%;
  align-items: center;
  margin: 2px;
  background-color: 'primary.main';
  border-radius: 3px;
  padding: 4px;
  border-style: none;
  transition: background-color ease-in-out 0.1s, width ease-in-out 0.2s;

  .RegionDateContainer {
    text-align: left;

    .incidentDate {
      margin: 0;
      font-size: 0.7em;
      text-align: left;
      color: 'text.secondary';
    }

    .incidentRegion {
      margin: 0;
      padding-right: 1em;
      font-size: 0.7em;
      text-align: left;
      color: 'text.secondary';
      font-weight: 800;
    }
  }

  .incidentIconContainer {
    flex: 1;
    max-width: 40px;
    padding: 0.2em;
    display: flex;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: 'primary.light';
    cursor: pointer;
  }
}

.incidentContent {
  flex: 5;
  margin: 0.75rem;
}

.incidentTitle {
  color: 'text.primary';
  font-weight: bold;
  text-align: left;
  margin: 0;
  font-size: 0.8em;
}

.incidentSummary {
  color: 'text.primary';
  text-align: left;
  line-height: 1.4em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  max-height: 3em;
  font-size: 0.8em;
  margin: 0;
  min-width: 0;
}
