@import '/src/mixins';

.map-lat-long-picker {
  color: 'secondary.main';
  cursor: pointer;

  & :hover {
    color: white;
  }

  &.active {
    color: white !important;
  }
}
